<template>
  <ContentWrapper>
    <template v-slot:title>Add New Contract Specification</template>
    <Container class="contract-wrapper">
      <div class="save-button">
        <base-button @click="sendContract"
          ><font-awesome-icon icon="download" /> Save</base-button
        >
      </div>
      <div class="contract-options">
        <div class="contract-options-form">
          <div class="form-group">
            <label for="exchange">Exchange*</label>
            <input
              type="text"
              id="exchange"
              v-model="form.exchange"
              placeholder="E.g Chicago Mercantile Exchange (CME)"
            />
          </div>
          <div class="form-group">
            <label for="product">Product*</label>
            <input
              type="text"
              id="product"
              v-model="form.product"
              placeholder="E.g E-mini S&P 500 Futures"
            />
          </div>
          <div class="form-group">
            <label for="productSymbol">Product Symbol</label>
            <input
              type="text"
              id="productSymbol"
              v-model="form.product_symbol"
              placeholder="E.g ES"
            />
          </div>
          <div class="form-group">
            <label for="contractUnit">Contract Unit</label>
            <input type="text" id="contractUnit" v-model="form.contract_unit" />
          </div>
          <div class="form-group">
            <label for="minPrice">Min Price Fluctuation</label>
            <input
              type="text"
              id="minPrice"
              v-model="form.mini_price_fluctuation"
            />
          </div>
          <div class="form-group">
            <label for="valuePerTick">Value Per Tick</label>
            <input
              type="text"
              id="valuePerTick"
              v-model="form.value_per_tick"
            />
          </div>
          <div class="form-group">
            <label for="contractMonths">Contact Months</label>
            <input
              type="text"
              id="contractMonths"
              v-model="form.contract_month"
            />
          </div>
          <div class="form-group">
            <label for="tradingHours">Trading Hours</label>
            <input type="text" id="tradingHours" v-model="form.trading_hours" />
          </div>
          <div class="form-group">
            <label for="settlementMethod">Settlement Method</label>
            <input
              type="text"
              id="settlementMethod"
              v-model="form.settlement_method"
            />
          </div>
          <div class="form-group">
            <label for="initialMargin">Initial Margin</label>
            <input
              type="text"
              id="initialMargin"
              v-model="form.initial_margin"
            />
          </div>
          <div class="form-group">
            <label for="initialMarginValue">Initial Margin Value*</label>
            <input
              type="text"
              id="initialMarginValue"
              v-model="form.initial_margin_val"
            />
          </div>
          <div class="form-group">
            <label for="maintenanceMargin">Maintenance Margin</label>
            <input
              type="text"
              id="maintenanceMargin"
              v-model="form.maintenance_margin"
            />
          </div>
          <div class="form-group">
            <label for="maintenanceMarginValue">Maintenance Margin Value*</label>
            <input
              type="text"
              id="maintenanceMarginValue"
              v-model="form.maintenance_margin_val"
            />
          </div>
          <div class="form-group">
            <label for="multiplier">Multiplier*</label>
            <input type="text" id="multiplier" v-model="form.multiplier" />
          </div>
          <div class="form-group">
            <label for="sourceTitle">Source Title</label>
            <input type="text" id="sourceTitle" v-model="form.source_title" />
          </div>
          <div class="form-group">
            <label for="sourceUrl">Source Url</label>
            <input type="text" id="sourceUrl" v-model="form.source_url" />
          </div>
        </div>
      </div>
    </Container>
  </ContentWrapper>
</template>

<script>
import TableRow from "@/components/TableRow";
import { postContract } from "@/services/adminAPI/contracts.js";
import { eventBus } from "@/main.js";

export default {
  components: {
    TableRow,
  },
  data() {
    return {
      form: {
        exchange: "",
        product: "",
        product_symbol: "",
        contract_unit: "",
        mini_price_fluctuation: "",
        value_per_tick: "",
        contract_month: "",
        trading_hours: "",
        settlement_method: "",
        initial_margin: "",
        initial_margin_val: "",
        maintenance_margin: "",
        maintenance_margin_val: "",
        multiplier: "",
        source_title: "",
        source_url: "",
      },
      error: "",
    };
  },
  methods: {
    async sendContract() {
      eventBus.$emit("loader", true);
      try {
        await postContract(this.form);
        eventBus.$emit("loader", false);
        this.$swal({
          icon: "success",
          title: "Contract Created",
          showConfirmButton: true,
        });
      } catch (error) {
        eventBus.$emit("loader", false);
        this.$swal({
          icon: 'warning',
          title: "Contract Create Fail",
          text: error.response.data.userMessage,
          showConfirmButton: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.save-button {
  float: right;
  z-index: 2;
}

.contract-options {
  display: grid;
  grid-template-columns: 1fr;
  &-form {
    padding: 1rem;
  }
}

@include respond(phone) {
  .contract-wrapper {
    height: calc(100vh - 60px - 58px);
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    .contract-options {
      width: 100%;
    }
  }
}
</style>
